import styled from "styled-components";

const Wrapper = styled.section`
  display: grid;
  align-items: center;
  .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 1.38rem;
  }

  .form {
    max-width: 400px;
    border-top: 15px solid var(--primary-500);
  }
  .img-container {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto;

    margin-bottom: -1.5rem;
  }
  .logo-img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
    margin: 0.5px auto;
    margin-top: -3em;
    border-style: none;
  }

  .time-circle {
    height: 150px;
    width: 150px;
    padding-top: 35px;
    border-radius: 50%;
    display: inline-block;
    border: 6px solid #41d5de;
    animation: chgColor 3s infinite;
    position: relative;
  }
  .time-circle-p {
    display: "flex";
    justify-content: "center";
    font-size: "152px";
    font-weight: "bold";
    margin-top: "5px";
  }
  .circle-x {
    display: "flex";
    justify-content: "center";
    font-size: "200px";
    font-weight: "bold";
    margin-top: "15px";
  }
  @keyframes chgColor {
    0% {
      border: 6px solid;
      border-color: #41d5de;
    }
    50% {
      border: 6px solid;
      border-color: var(--primary-500);
    }
    100% {
      border: 6px solid;
      border-color: #41d5de;
    }
  }

  .btn {
    margin-top: 1rem;
  }
  .clear-btn {
    background: var(--black);
  }
  .member-btn {
    background: transparent;
    border: transparent;
    color: var(--primary-500);
    cursor: pointer;
    letter-spacing: var(--letterSpacing);
  }
  .info-window-content {
    font-size: 12px;
    padding: 0px;
  }

  .info-window-content h2 {
    font-size: 14px;
  }
`;
export default Wrapper;
