const FormRowSelect = ({ labelText, name, value, handleChange, list }) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label-small">
        {labelText || name}
      </label>
      <select
        name={name}
        value={value}
        onChange={handleChange}
        className="form-select"
      >
        {list.map((itemValue, index) => {
          return (
            <option key={index} value={itemValue.id}>
              {itemValue.id === ""
                ? ""
                : itemValue.id + " : " + itemValue.title}
              {/* {itemValue.id} : {itemValue.title} */}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default FormRowSelect;
