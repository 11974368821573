const FormRowRadio = ({
    type,
    name,
    value,
    handleChange,
    labelText,
    ...other
  }) => {
    return (
      <div className="form-row-radio">
        <input
          type={type}
          value={value}
          name={name}
          onChange={handleChange}
          {...other}
        />
        <p htmlFor={name} className="form-label-small">
          {labelText || name}
        </p>
      </div>
    );
  };
  
  export default FormRowRadio;
  