import React, { useReducer, useContext } from "react";
import moment from "moment";
import axios from "axios";
import reducer from "./reducer";

import {
  DISPLAY_ALERT,
  DISPLAY_ALERT_TEXT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  SETUP_LOAD_DATA_BEGIN,
  SETUP_LOAD_DATA_SUCCESS,
  SETUP_TIMESTAMP_BEGIN,
  SETUP_TIMESTAMP_SUCCESS,
  SETUP_TIMESTAMP_ERROR,
  SETUP_LEAVE_BEGIN,
  SETUP_LEAVE_SUCCESS,
  SETUP_LEAVE_ERROR,
  SETUP_TRNFER_BEGIN,
  SETUP_TRNFER_SUCCESS,
  SETUP_TRNFER_ERROR,
} from "./actions";

const initialState = {
  userLoading: true,
  isLoading: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  profile: null,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };

  const displayAlertText = ({ alertText }) => {
    dispatch({ type: DISPLAY_ALERT_TEXT, payload: { alertText } });
    clearAlert();
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 2300);
  };

  const setupUser = async ({ currentUser, endPoint, alertText, liff }) => {
    dispatch({ type: SETUP_USER_BEGIN });

    const { username, password, userId } = currentUser;

    console.log(process.env.REACT_APP_API)
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/auth/${endPoint}`,
        { username, password, line_uid: userId }
      );
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { alertText },
      });

      setTimeout(() => {
        liff.closeWindow();
      }, 1000);
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: {
          msg: error.response.data
            ? error.response.data.message[0]
            : "error no response",
        },
      });
    }
    clearAlert();
  };

  const loadData = async ({ profile, endPoint, alertText }) => {
    dispatch({
      type: SETUP_LOAD_DATA_BEGIN,
      payload: { alertText },
    });
    try {
      let userId = profile.userId;
      // userId = "U515435fb6625d9a98c287a0c4e896c4e";

      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/auth/${endPoint}/${userId}`
        // {
        //   headers: {
        //     "ngrok-skip-browser-warning": "true",
        //   },
        // }
      );
      // console.log(data);
      dispatch({
        type: SETUP_LOAD_DATA_SUCCESS,
        payload: {
          profile: { ...data.profile, ...data.vacation },
          alertText: "Load Data Success...!!",
        },
      });
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: {
          msg: error.response.data
            ? error.response.data.message[0]
            : "error no response",
        },
      });
    }
    clearAlert();
  };

  const createTimestamp = async ({ values, timestamp, liff }) => {
    dispatch({ type: SETUP_TIMESTAMP_BEGIN });

    try {
      let userId = values.userId;
      //   userId = "U515435fb6625d9a98c287a0c4e896c4e";
      const dateAt = timestamp.dateAt;
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/timestamp/${userId}/${dateAt}`,
        {
          user_id: timestamp.user_id,
          start_time: timestamp.start_time,
          key_time: timestamp.key_time,
          key_location: timestamp.key_location,
          key_address: timestamp.key_address,
          wfh: timestamp.wfh,
        }
      );

      dispatch({
        type: SETUP_TIMESTAMP_SUCCESS,
        payload: { alertText: `ลงเวลา สำเร็จ...!!` },
      });

      setTimeout(() => {
        liff.closeWindow();
      }, 1000);
    } catch (error) {
      dispatch({
        type: SETUP_TIMESTAMP_ERROR,
        payload: {
          msg: error.response.data
            ? error.response.data.message[0]
            : "error no response",
        },
      });
    }
    clearAlert();
  };

  const createLeave = async ({ values, leave, liff }) => {
    dispatch({ type: SETUP_LEAVE_BEGIN });

    try {
      let userId = values.userId;
      // userId = "U515435fb6625d9a98c287a0c4e896c4e";

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/leaves/${userId}`,
        { ...leave }
      );

      dispatch({
        type: SETUP_LEAVE_SUCCESS,
        payload: { alertText: `ลา สำเร็จ...!!` },
      });

      setTimeout(() => {
        liff.closeWindow();
      }, 1000);
    } catch (error) {
      dispatch({
        type: SETUP_LEAVE_ERROR,
        payload: {
          msg: error.response.data
            ? error.response.data.message[0]
            : "error no response",
        },
      });
    }
    clearAlert();
  };
  const SyncVacations = async () => {
    dispatch({ type: SETUP_TRNFER_BEGIN });

    try {
      let userId = state.profile.id;

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/tranfers/vacations/${userId}`
      );

      dispatch({
        type: SETUP_TRNFER_SUCCESS,
        payload: { alertText: `อัพเดทข้อมูล สำเร็จ ...!!` },
      });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      dispatch({
        type: SETUP_TRNFER_ERROR,
        payload: {
          msg: error.response.data
            ? error.response.data.message[0]
            : "error no response",
        },
      });
    }
    clearAlert();
  };
  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        displayAlertText,
        setupUser,
        loadData,
        createTimestamp,
        createLeave,
        SyncVacations,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
