import { useState, useEffect } from "react";

import liff from "@line/liff";
import moment from "moment";
import { RefreshOutlined, SyncAltOutlined } from "@mui/icons-material";
import Wrapper from "../assets/wrappers/LeavePage";
import WrapperTop from "../assets/wrappers/LeavePageNavbar";
import { useAppContext } from "../context/appContext";
import logoImage from "../assets/images/gl_logo.jpg";
import { FormRowSelect, FormRowDatetime, Alert } from "../components";
import { LIFF_ID_LEAVE } from "../assets/lineIffUrls";
import { leaveType, leaveEvent, calculateBusinessDays } from "../utils/index";

const disabledDateRanges = [
  {
    start: new Date(moment().startOf("year")),
    end:
      moment().format("MM") === "01"
        ? new Date(moment().startOf("year").format("YYYY-MM-DD"))
        : new Date(moment(moment()).subtract(1, "months").format("YYYY-MM-DD")),
  },
];

const initState = {
  userId: "",
  pictureUrl: "",
  leaveType: "",
  leaveName: "",
  dateStart: "",
  dateEnd: "",
  daysNumber: 0,
  beginTime: "",
  endTime: "",
  hour: "00:00",
  isfullDay: true,
  leaveEventType: "1",
  leaveEventName: "เต็มวัน",
};

const Leave = () => {
  const {
    isLoading,
    profile,
    showAlert,
    displayAlert,
    displayAlertText,
    loadData,
    createLeave,
    SyncVacations,
  } = useAppContext();

  const [values, setValues] = useState(initState);
  const clearValues = () => {
    setValues(initState);
  };

  const fetchData = async (profile) => {
    loadData({
      profile,
      endPoint: "profile",
      alertText: "Load Data Please wait...!!",
    });
  };

  const handleDateChange = (update, name) => {
    let { dateStart, dateEnd } = "";
    let dayDiff;
    if (update[0]) {
      dateStart = update[0];
    }
    if (update[1]) {
      dateEnd = update[1];
    }

    const isDay = dateStart && dateEnd ? true : false;

    if (isDay) {
      dayDiff = calculateBusinessDays(dateStart, dateEnd);
    }

    if (values.isfullDay === false) {
      dayDiff = 0;
    }

    setValues({
      ...values,
      dateStart: dateStart,
      dateEnd: dateEnd,
      daysNumber: dayDiff === undefined || dayDiff <= 0 ? 0 : dayDiff,
    });
  };

  const handleFullDayChange = (e) => {
    const { selectedIndex } = e.target;
    let { beginTime, endTime, hour } = "";
    let isfullDay;
    let dayDiff;

    const isDay = values.dateStart && values.dateEnd ? true : false;

    if (isDay) {
      dayDiff = calculateBusinessDays(values.dateStart, values.dateEnd);
    }

    // const leaveTextEvent = [
    //   { id: "1", name: "fullDay" },
    //   { id: "2", name: "haftDayMorning" },
    //   { id: "3", name: "haftDayAfternoon" },
    // ];

    const _leaveEventid = leaveEvent[selectedIndex].id;
    const _leaveEventText = leaveEvent[selectedIndex].title;
    if (_leaveEventid === "1") {
      //fullDay
      beginTime = "";
      endTime = "";
      hour = "00:00";
      isfullDay = true;
    } else if (_leaveEventid === "2") {
      //haftDayMorning
      beginTime = "08:30";
      endTime = "12:30";
      hour = "04:00";
      isfullDay = false;
      dayDiff = 0;
    } else if (_leaveEventid === "3") {
      //haftDayAfternoon
      beginTime = "13:30";
      endTime = "17:30";
      hour = "04:00";
      isfullDay = false;
      dayDiff = 0;
    }
    setValues({
      ...values,
      beginTime: beginTime,
      endTime: endTime,
      hour: hour,
      isfullDay: isfullDay,
      daysNumber: dayDiff === undefined || dayDiff <= 0 ? 0 : dayDiff,
      leaveEventType: _leaveEventid,
      leaveEventName: _leaveEventText,
    });
  };

  const initLine = async () => {
    liff.init({ liffId: LIFF_ID_LEAVE }).then(() => {
      if (liff.isLoggedIn()) {
        liff.getProfile().then((profile) => {
          fetchData(profile);
          setValues({
            ...values,
            userId: profile.userId,
            pictureUrl: profile.pictureUrl,
          });
        });
      } else {
        liff.login();
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      !values.userId ||
      !values.leaveType ||
      !values.dateStart ||
      !values.dateEnd ||
      !values.leaveEventType
    ) {
      displayAlert();
      return;
    }

    if (
      moment(values.dateStart).format("YYYY-MM-DD") >
      moment(values.dateEnd).format("YYYY-MM-DD")
    ) {
      displayAlertText({ alertText: "วันเริ่มต้น > ถึงวันที่สุดท้าย" });
      return;
    }

    if (
      !values.isfullDay &&
      moment(values.dateStart).format("YYYY-MM-DD") !==
        moment(values.dateEnd).format("YYYY-MM-DD")
    ) {
      displayAlertText({
        alertText: "ลาครึ่งวัน วันที่ - ถึง วันที่ ต้องวันเดียวกัน",
      });
      return;
    }

    if (
      ["2", "พ"].includes(values.leaveType) &&
      Number(values.daysNumber) >
        Number(profile.vacations_balance - profile.vacations_leave_pending)
    ) {
      displayAlertText({
        alertText: `วันลา เหลือ ${
          profile.vacations_balance - profile.vacations_leave_pending
        } วัน`,
      });
      return;
    }

    const leave = {
      user_id: profile.id,
      start_date: moment(values.dateStart).format("YYYY-MM-DD"),
      end_date: moment(values.dateEnd).format("YYYY-MM-DD"),
      days_number: values.daysNumber,
      begin_time: values.beginTime,
      end_time: values.endTime,
      hours_number: values.hour,
      leave_type: values.leaveType,
      status: "Pending",
      approve_user_id: "",
      approve_datetime: null,
      data_from: "line",
      pictureUrl: values.pictureUrl || null,
    };

    createLeave({ values, leave, liff });
  };

  useEffect(() => {
    return () => {
      initLine();
    };
  }, []);

  return (
    <Wrapper>
      <div className="block bcg-black"></div>
      <div className="block">
        <div className="container" style={{ marginTop: "-300px" }}>
          <form className="form" onSubmit={onSubmit}>
            {/* <div>{JSON.stringify(values)}</div> */}
            <WrapperTop>
              <div className="nav-center">
                <div className="top-left">
                  <div className="icon-container">
                    <img
                      src={values.pictureUrl || logoImage}
                      alt="logo"
                      className="img-avatar"
                    />
                  </div>
                  <div className="icon-container">
                    <div className="text-btn">
                      {profile
                        ? `${profile.id} : ${profile.username} ${
                            profile.wfh === "Y" ? "wfh" : ""
                          }`
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="top-right">
                  <div
                    className="icon-container"
                    style={{ marginRight: "0.5rem" }}
                  >
                    <div className="refresh-btn" onClick={SyncVacations}>
                      <SyncAltOutlined />
                    </div>
                  </div>
                  <div className="icon-container">
                    <div
                      className="refresh-btn"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      <RefreshOutlined />
                    </div>
                  </div>
                </div>
              </div>
            </WrapperTop>

            <div style={{ marginTop: "5px", textAlign: "start" }}>
              <p className="user-text-small">
                {profile
                  ? `วันที่เริ่ม : ${moment(
                      profile.vacations_start_date
                    ).format("DD MMMM YYYY")}`
                  : ""}
              </p>
              <p className="user-text-small">
                {profile
                  ? `วันลาพักร้อน  : ${profile.vacations_total} วัน`
                  : ""}
                {" , "}
                {profile ? ` คงเหลือ  : ${profile.vacations_balance}  วัน` : ""}
              </p>
              <p className="user-text-small">
                {profile
                  ? `วันลาใช้ได้ : ${
                      profile.vacations_balance -
                      profile.vacations_leave_pending
                    } วัน`
                  : ""}
                {" , "}
                {profile
                  ? `คงเหลือ  : ${profile.vacations_leave_pending}  วัน`
                  : ""}
              </p>
              {showAlert && <Alert />}
              <hr className="rounded" />
            </div>
            <div>
              <FormRowSelect
                labelText="ประเภทการลา"
                name="leaveType"
                value={values.leaveType || ""}
                handleChange={(e) => {
                  setValues({
                    ...values,
                    [e.target.name]: e.target.value,
                    leaveName: leaveType[e.target.selectedIndex].title,
                  });
                }}
                list={leaveType}
              />
              <FormRowDatetime
                labelText="วันที่ - ถึง วันที่"
                name="dateRange"
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy - dd/mm/yyyy"
                selectsRange={true}
                startDate={values.dateStart}
                endDate={values.dateEnd}
                isClearable
                disabledKeyboardNavigation
                todayButton={"Today"}
                withPortal
                excludeDateIntervals={disabledDateRanges}
                // filterDate={(date) => date.getDay() !== 6 && date.getDay() !== 0}
                filterDate={(date) => date.getDay() !== 0}
                onChange={(update) => handleDateChange(update, "dateRange")}
              />
              <FormRowSelect
                labelText="รูปแบบลา"
                name="leaveEventType"
                value={values.leaveEventType || "1"}
                handleChange={handleFullDayChange}
                list={leaveEvent}
              />
            </div>
            <div className="btn-container">
              <button
                className="btn btn-block"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "loading..." : "บันทึก"}
              </button>
              <button
                className="btn btn-block btn-hipster"
                type="button"
                disabled={isLoading}
                onClick={() => {
                  clearValues();
                }}
              >
                {isLoading ? "loading..." : "เคลียร์"}
              </button>
            </div>
            <div
              className="btn-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                type="button"
                className="btn btn-block btn-hipster"
                disabled={isLoading}
                onClick={() => {
                  liff.closeWindow();
                }}
              >
                {isLoading ? "loading..." : "ปิด"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default Leave;
